import { doc, addDoc, setDoc, getDoc, getDocs, deleteDoc, query, collection, orderBy, where, limit } from "firebase/firestore";
import { getDb } from "./db.mjs"

const users_collection_name = "users"
const bloompoint_users_collection_name = "bloomPointUsers"
const profiles_collection_name = "profiles"
const bookmarks_collection_name = "bookmarks"

export const findAllLinkedProfilesByUid = async (uid, profileIdList) => {
    const res = []
	
	const querySnapshot = await getDocs(query(collection(getDb(), profiles_collection_name), where('uid', '==', uid), orderBy('sequence', 'desc')))
		
	querySnapshot.forEach((profile) => {
	  //console.log(`${profile.id} => ${profile.data()}`);
	  if(profileIdList.indexOf(profile.id) !== -1){
		  res.push({
				id: profile.id, 
				...profile.data()
			})
	  }
	});
	
    return res
}

export const putUserCredsIntoUserModel = async (uid, userData) => {
	const snapshot = await getDoc(doc(getDb(), bloompoint_users_collection_name, uid)) 
	//console.log(snapshot)
	if (!snapshot.exists()) {
		const docRefUser = await setDoc(doc(getDb(), bloompoint_users_collection_name, uid), userData);
		//console.log(docRefUser)
		//const docRefProfile = await addDoc(collection(getDb(), users_collection_name, uid, profiles_collection_name), profileData);
	}
}

export const findAllBookmarksByUid = async (uid) => {
	const res = []
	
	const querySnapshot = await getDocs(query(collection(getDb(), bookmarks_collection_name), where('bloomPointUid', '==', uid), orderBy('createdAt', 'desc')))
		
	querySnapshot.forEach((bookmark) => {
	  res.push({
			id: bookmark.id, 
		  	bloomName: bookmark.bloomName,
			bookmarkURL: '/' + bookmark.bloomName,
			...bookmark.data()
		})
	});
	
    return res
}

export const findBookmarkByBloomName = async (uid, bloomName) => {
	if(uid == null){
		return null
	}
	
	//const querySnapshot = await getDoc(doc(getDb(), bloompoint_users_collection_name, uid, bookmarks_collection_name, bloomName))
	const snapshot = await getDocs(query(collection(getDb(), bookmarks_collection_name), where('bloomPointUid', '==', uid), where('bloomName', '==', bloomName), limit(1)))


	if (snapshot.docs.length > 0) {
		return snapshot.docs[0]
	}
	/*querySnapshot.forEach((bookmark) => {
	  return bookmark;
	});*/
}

export const putBookmarkByBloomName = async (uid, bloomName) => {
	var now = new Date().toUTCString();
	var bookmarkData = {'bloomPointUid':uid, 'bloomName':bloomName, 'createdAt':now, 'modifiedAt':now}
	const snapshot = await getDocs(query(collection(getDb(), bookmarks_collection_name), where('bloomPointUid', '==', uid), where('bloomName', '==', bloomName), limit(1)))
	
	if (snapshot.docs.length == 0) {
		const docRefBookmark = await addDoc(collection(getDb(), bookmarks_collection_name), bookmarkData);
	}
}

export const deleteBookmarkById = async (id) => {
  try {
    //await deleteDoc(query(collection(getDb(), bookmarks_collection_name), where('bloomPointUid', '==', uid), where('bloomName', '==', bloomName)))
    await deleteDoc(doc(getDb(), bookmarks_collection_name, id))
    console.log("Bookmark has been deleted successfully.");
  } catch(ex) {
    console.log(ex); 
  }
}

import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useUserAuth } from "../context/UserAuthContext";
import Dropdown from 'react-bootstrap/Dropdown';

import { addBloomViewCountByBloomName, findBloomByBloomName, findAllBloomLinksByBloomName, findAllBloomFeatureImagesByBloomName } from '../services/bloom.mjs'
import { findAllLinkedProfilesByUid, findBookmarkByBloomName } from '../services/profile.mjs'
import Profiles from '../components/profiles.js'
import BloomLinks from '../components/bloom-links.js'
import BloomFeatureImages from '../components/bloom-feature-images.js'

const onSelect = (eventKey, event) => {
	const el1 = document.querySelectorAll('.profile-container');
	for (var i = 0; i < el1.length; ++i) {
	   el1[i].classList.add("visually-hidden");
	}
	
	const el2 = document.querySelector('#' + eventKey);
	el2.classList.remove("visually-hidden");
}

function Home() {
    const {paramBloomName} = useParams();
	const { user } = useUserAuth();
	
    const [loading, setLoading] = useState(false)
    const [ipAddressView, setIPAddressView] = useState([])
    const [bloomName, setBloomName] = useState('')
    const [bloomBio, setBloomBio] = useState('')
    const [bloomLinks, setBloomLinks] = useState([])
    const [bloomFeatureImages, setBloomFeatureImages] = useState([])
    const [profiles, setProfiles] = useState([])
    const [bookmark, setBookmark] = useState({})
    const [defaultProfileId, setDefaultProfileId] = useState('')
	
    const fetchData = async () => {
		if(user == null || user.uid != null){
			setLoading(true)
			const bloomRes = await findBloomByBloomName(paramBloomName)

			const bloomLinksRes = await findAllBloomLinksByBloomName(paramBloomName)
			const BloomFeatureImagesRes = await findAllBloomFeatureImagesByBloomName(paramBloomName)
			const profilesRes = await findAllLinkedProfilesByUid(bloomRes.uid, bloomRes.profileIdList)
			
			if(user != null){
				const bookmarkRes = await findBookmarkByBloomName(user.uid, paramBloomName)
				if(bookmarkRes != null){
					setBookmark(bookmarkRes)
				}
			}
			await addBloomViewCountByBloomName(paramBloomName)

			setBloomName(paramBloomName)
			setBloomBio(bloomRes.bio)
			setBloomLinks([...bloomLinksRes])
			setBloomFeatureImages([...BloomFeatureImagesRes])
			setProfiles([...profilesRes])
			
			setDefaultProfileId()
			setLoading(false)
		}
    }

    useEffect(() => {
		fetchData()
    }, [user])
					
    return (
		<div>
			{ loading && 
				<p>loading...</p>
			}
			
			<div className="bloom-content">	
				<div className="profile-content">	
					<Dropdown onSelect={onSelect}>
					  <Dropdown.Toggle variant="success" id="dropdown-profile">
						{profiles.length > 0  ?　profiles[0].displayName : ''}
					  </Dropdown.Toggle>

					  <Dropdown.Menu>
						{profiles.length > 0 && profiles.map(function(p, i){    
							//if(p.id == profile.id){
							//	return <Dropdown.Item eventKey={p.id} active>{p.displayName}</Dropdown.Item>;
							//}else{
								return <Dropdown.Item eventKey={"profile" + i}>{p.displayName}</Dropdown.Item>;
							//}
						})}
					  </Dropdown.Menu>
					</Dropdown>
					{profiles.length > 0 && profiles.map(function(profile, i){    
						if(i==0){
							return <Profiles counter={i} user={user} profile={profile} bloomName={bloomName} bookmark={bookmark} isHidden={false}/>;
						}else if(profiles.length > 1){
							return <Profiles counter={i} user={user} profile={profile} bloomName={bloomName} bookmark={bookmark} isHidden={true}/>;
						}				
					})}
				</div>
				<p className="bloom-bio">{bloomBio}</p>
				<ul className="feature-images-container">
				{bloomFeatureImages.length > 0 && bloomFeatureImages.map(bloomFeatureImage => (
					<BloomFeatureImages bloomFeatureImage={bloomFeatureImage}/>
				))}
				</ul>
				<div className="link-container">
					<h4>Links</h4>
					<ul className="bloom-links-list">
						{bloomLinks.length > 0 && bloomLinks.map(bloomLink => (
							<BloomLinks bloomName={bloomName} bloomLink={bloomLink}/>
						))}
					</ul>
				</div>
			</div>
		</div>
    )
}

export default Home

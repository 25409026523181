import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { auth } from "../utils/firebase-utils.js"
import { onAuthStateChanged, signOut, signInWithPopup,  GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { putUserCredsIntoUserModel } from "../services/profile.mjs"

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  function handleLogout() {
    signOut(auth);
	navigate('/');
  }
  
  const handleGoogleFirebaseSignIn = async () => {
    const authProvider = new GoogleAuthProvider();
	
    const response =  await signInWithPopup(auth, authProvider);

	//console.log(response.user);
	
	if(response != null && response.user != null){
		const uid = response.user.uid
		const email = response.user.email
		const platform = 'Google'
		
		const displayName = response.user.displayName
		const photoUrl = response.user.photoURL

		var now = new Date().toUTCString();
		const userData = {'email': email, 'displayName':displayName, 'photoUrl':photoUrl, 'platform':platform, 'createdAt': now, 'modifiedAt': now}
		//const profileData = {'sequence':0, 'email': email, 'displayName':displayName, 'photoUrl':photoUrl, 'industry': [''], 'address': null, 'phoneCode': null, 'phone': null, 'placeId': null, 'postalCode': null, 'isWhatsapp': null, 'createdAt': now, 'modifiedAt': now}

		putUserCredsIntoUserModel(uid, userData)
	}
	navigate(-1);
  }
  
  const handleFacebookFirebaseSignIn = async () => {
    const authProvider = new FacebookAuthProvider();
	  
    const response =  await signInWithPopup(auth, authProvider);

	//console.log(response.user);
	
	if(response != null && response.user != null){
		const uid = response.user.uid
		const email = response.user.email
		const platform = 'Facebook'
		
		const displayName = response.user.displayName
		const photoUrl = response.user.photoURL

		var now = new Date().toUTCString();
		const userData = {'email': email, 'displayName':displayName, 'photoUrl':photoUrl, 'platform':platform, 'createdAt': now, 'modifiedAt': now}
		//const profileData = {'sequence':0, 'email': email, 'displayName':displayName, 'photoUrl':photoUrl, 'industry': [''], 'address': null, 'phoneCode': null, 'phone': null, 'placeId': null, 'postalCode': null, 'isWhatsapp': null, 'createdAt': now, 'modifiedAt': now}

		putUserCredsIntoUserModel(uid, userData)
	}
	navigate(-1);
  }
  
  /*const handleTokenFirebaseSignIn = async ({ token }) => {	  
    
    const response =  await auth.verifyIdToken(token);
	console.log(response.uid);

	//console.log(response.user);
	
	/*if(response != null && response.user != null){
		const uid = response.user.uid
		const email = response.user.email
		const platform = 'Facebook'
		
		const displayName = response.user.displayName
		const photoUrl = response.user.photoURL

		var now = new Date().toUTCString();
		//const userData = {'email': email, 'displayName':displayName, 'photoUrl':photoUrl, 'platform':platform, 'createdAt': now, 'modifiedAt': now}
		
		putUserCredsIntoUserModel(uid, userData)
	}*/
	/*navigate(-1);
  }*/

   useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      console.log("Auth", currentuser);
      setUser(currentuser);
    });
	
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{ user, handleLogout, handleGoogleFirebaseSignIn, handleFacebookFirebaseSignIn }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; 

let db = false;
let auth = false;

export const getDb = () => {
    if(!db){
        const firebaseConfig = {
			  apiKey: "AIzaSyCjFDTLWiErSjeLpKVHRWRtBUgDxs1xrRY",
			  authDomain: "kokkok-3f3aa.firebaseapp.com",
			  projectId: "kokkok-3f3aa",
			  storageBucket: "kokkok-3f3aa.appspot.com",
			  messagingSenderId: "1087081375255",
			  appId: "1:1087081375255:web:666339dbdee17ec02e1e52",
			  measurementId: "G-CX5ZGBZG2X"
        }

        const app = initializeApp(firebaseConfig)

        db = getFirestore(app)
    }

    return db
}

export const getDbAuth = () => {
    if(!auth){
        const firebaseConfig = {
			  apiKey: "AIzaSyCjFDTLWiErSjeLpKVHRWRtBUgDxs1xrRY",
			  authDomain: "kokkok-3f3aa.firebaseapp.com",
			  projectId: "kokkok-3f3aa",
			  storageBucket: "kokkok-3f3aa.appspot.com",
			  messagingSenderId: "1087081375255",
			  appId: "1:1087081375255:web:666339dbdee17ec02e1e52",
			  measurementId: "G-CX5ZGBZG2X"
        }

        const app = initializeApp(firebaseConfig)

        auth = getAuth(app)
    }

    return auth
}
import { Routes, Route } from 'react-router-dom';

import Redirect from "./redirect.js";
import Home from '../pages/home.jsx';
import Login from '../pages/login.jsx';
import Bookmark from '../pages/bookmark.jsx';
import BloomTap from '../pages/bloomtap.jsx';
import Privacy from '../pages/privacy.jsx';
import Tnc from '../pages/tnc.jsx';
import FbDataDeletion from '../pages/fb-data-deletion.jsx';
import GoogleDataDeletion from '../pages/google-data-deletion.jsx';
import ProtectedRoute from "./protected-router.js";

const Main = () => {
  return (
	<Routes> 
	  <Route path="/" element={<Redirect url={ "https://shop.bloomtap.io" } />} />
	  <Route path="/:paramBloomName" element={<Home />} />
	  <Route path="/:paramBloomName/:profileId" element={<Home />} />
	  <Route path="/bloomtap/:btid" element={<BloomTap />} />
	  <Route path="/login" element={<Login />} />
	  <Route path="/bookmark" element={<ProtectedRoute Component={Bookmark} />} />
	  <Route path="/privacy" element={<Privacy />} />
	  <Route path="/tnc" element={<Tnc />} />
	  <Route path="/fb-data-deletion" element={<FbDataDeletion />} />
	  <Route path="/google-data-deletion" element={<GoogleDataDeletion />} />
	</Routes>
  );
}

export default Main;

import { putBookmarkByBloomName, deleteBookmarkById } from '../services/profile.mjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faEnvelope, faHeart, faLocationDot, faLink, faMap } from '@fortawesome/free-solid-svg-icons'
import icGoogleMap from '../images/ic_google_map.png'


const handleBookmark = (user, bookmark, bloomName, e) => {
    e.preventDefault();
	if(user != null){
		if(bookmark.id != null){
			deleteBookmarkById(bookmark.id)
		}else{
			putBookmarkByBloomName(user.uid, bloomName)
		}
		setTimeout(function() {
		  window.location.reload(false);
		}, 1000);
	}else{
		window.location.href = "/login?action=bookmark&bloomname=" +bloomName;
	}
};

function Profiles(props) {
    const { counter, user, profile, bloomName, bookmark, isHidden } = props
	const bookmarkLink = user != null ? '#' : '/login'
	const bookmarkStyle = bookmark.id != null ? 'second-color' : ''
    var goolgeMapUrl = profile.placeId != null ? "https://www.google.com/maps/search/?api=1&query=Google&query_place_id=" + profile.placeId : "#"
    
    return (
		<div>
			<div id={"profile" + counter} className={"profile-container " + (isHidden ? 'visually-hidden' : '')}>
				<div className="profile-image-container">
					<img className="profile-image" src={profile.photoUrl} alt="BloomTap Profile Image"/>
					<div className="nav-profile">
						<ul className="nav-tab-profile">
							<li><a className="cus-btn black-btn" href={bookmarkLink} onClick={handleBookmark.bind(this, user, bookmark, bloomName)}><FontAwesomeIcon icon={faHeart} className={bookmarkStyle}/></a></li>
								{profile.isWhatsapp == true ? (
									<li><a className="cus-btn black-btn" href={"https://wa.me/+" + profile.phoneCode + profile.phone+ "?text=Hi!"}><FontAwesomeIcon icon={faWhatsapp}  /></a></li>
								  ) : (
									<li><a className="cus-btn black-btn" href={"mailto:" + profile.email}><FontAwesomeIcon icon={faEnvelope}  /></a></li>
								  )}
							<li><a className="cus-btn black-btn" href={"tel:+" + profile.phoneCode + profile.phone}><FontAwesomeIcon icon={faPhone}  /></a></li>
						</ul>
					</div>
				</div>
				<div className="profile-bloomname"><FontAwesomeIcon icon={faLink}  />  {bloomName}</div>
				<div className="profile-address"><FontAwesomeIcon icon={faMap}  />  <a href={goolgeMapUrl} target="_blank">{profile.address}</a></div>
			</div>
		</div>
    )
}

export default Profiles

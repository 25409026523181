import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUserAuth } from "../context/UserAuthContext";

import { findAllBloomLinksByBloomName, findAllBloomFeatureImagesByBloomName } from '../services/bloom.mjs'
import { findAllBookmarksByUid } from '../services/profile.mjs'
import Bookmarks from '../components/bookmarks.js'

function Bookmark() {
	const { user } = useUserAuth();
	
    const [loading, setLoading] = useState(false)
    const [bookmarks, setBookmarks] = useState([])
	
    const fetchData = async () => {
        setLoading(true)
		const bookmarkRes = await findAllBookmarksByUid(user.uid)

		if(bookmarkRes != null){
			setBookmarks([...bookmarkRes])
		}
        setLoading(false)
    }

    useEffect(() => {
		if(user != null){
			fetchData()
		}
    }, [user])

					
    return (
        <div>
            { loading && 
                <p>loading...</p>
            }
			
			<div className="bloom-content">	
				<div className="link-container">
					<h4>Bookmarks</h4>
					<ul className="bookmark-list">
						{bookmarks.length > 0 && bookmarks.map(bookmark => (
							<Bookmarks user={user} bookmark={bookmark}/>
						))}
					</ul>
					{bookmarks.length == 0 && <p>You have no bookmark</p>}
				</div>
			</div>
        </div>
    )
}

export default Bookmark

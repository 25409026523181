import { doc, updateDoc, setDoc, getDoc, getDocs, query, collection, increment } from "firebase/firestore";
import { getDb } from "./db.mjs"

const blooms_collection_name = "blooms"
const bloom_links_collection_name = "bloomLinks"
const bloom_feature_images_collection_name = "bloomFeatureImages"
const bloom_view_collection_name = "bloomViews"
const bloom_link_collection_name = "bloomLinks"
const bloom_link_click_collection_name = "bloomLinkClicks"


export const addBloomViewCountByBloomName = async bloomName => {
	const today = new Date();
	const month = (today.getMonth() + 1).toString().padStart(2, '0');
	const year = today.getFullYear();
	const date = today.getDate().toString().padStart(2, '0');
	const currentDate = year + "-" + month + "-" + date;
		
	const isPageViewed = sessionStorage.getItem("vc" + bloomName);
		
	if(isPageViewed == null){
		await updateDoc(doc(getDb(), blooms_collection_name, bloomName), {viewCounter: increment(1)});
		await setDoc(doc(getDb(), blooms_collection_name, bloomName, bloom_view_collection_name, currentDate), {viewCounter: increment(1)}, {merge: true});
	}
	
	sessionStorage.setItem("vc" + bloomName, true);
}

export const addBloomLinkClickCountByBloomNameAndLinkId = async (bloomName, linkId) => {
	const today = new Date();
	const month = (today.getMonth() + 1).toString().padStart(2, '0');
	const year = today.getFullYear();
	const date = today.getDate().toString().padStart(2, '0');
	const currentDate = year + "-" + month + "-" + date;
		
	const isLinkClicked = sessionStorage.getItem("lcc" + linkId);
		
	if(isLinkClicked == null){
		await updateDoc(doc(getDb(), blooms_collection_name, bloomName), {linkClickCounter: increment(1)});
		await updateDoc(doc(getDb(), blooms_collection_name, bloomName, bloom_links_collection_name, linkId), {linkClickCounter: increment(1)});
		await setDoc(doc(getDb(), blooms_collection_name, bloomName, bloom_link_click_collection_name, currentDate), {linkClickCounter: increment(1)}, {merge: true});
	}
	
	sessionStorage.setItem("lcc" + linkId, true);
}

export const findBloomByBloomName = async bloomName => {
    const querySnapshot = await getDoc(doc(getDb(), blooms_collection_name, bloomName)) 
    return querySnapshot.data()
}

export const findAllBloomLinksByBloomName = async bloomName => {
    //const doc_refs = await getDocs(collection(getDb(), blooms_collection_name, bloomName))
	
    const res = []
	
	const querySnapshot = await getDocs(query(collection(getDb(), blooms_collection_name, bloomName, bloom_links_collection_name)))
		
	querySnapshot.forEach((bloomLink) => {
	  //console.log(`${bloomLink.id} => ${bloomLink.data()}`);
	  res.push({
			id: bloomLink.id, 
			...bloomLink.data()
		})
	});
	
    return res
}

export const findAllBloomFeatureImagesByBloomName = async bloomName => {	
    const res = []
	
	const querySnapshot = await getDocs(query(collection(getDb(), blooms_collection_name, bloomName, bloom_feature_images_collection_name)))
		
	querySnapshot.forEach((bloomFeatureImage) => {
	  //console.log(`${bloomLink.id} => ${bloomLink.data()}`);
	  res.push({
			id: bloomFeatureImage.id, 
			...bloomFeatureImage.data()
		})
	});
	
    return res
}

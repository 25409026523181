import MainNavbar from './navbar.js'
import Footer from './footer.js'
import Cookies from 'js-cookie';
//import { useUserAuth } from "../context/UserAuthContext";

export default function Layout({ children }) {
  /*const { handleTokenFirebaseSignIn, user } = useUserAuth();
  if(Cookies.get('appAuth') != null && Cookies.get('appAuth') != undefined){
	  var token = Cookies.get('appAuth');
	  handleTokenFirebaseSignIn(token);
  }*/
	
  return (
    <>
      <div className="navbar-container">
		{(Cookies.get('appAuth') == null || Cookies.get('appAuth') == undefined) &&
		<MainNavbar />	
		}
	  </div>
	  <div className="layout-container pt-4 pb-4">
		  <main>{children}</main>
		  <Footer />
	  </div>
    </>
  )
}

import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";

const ProtectedRoute = ({ Component }) => {
	const { user } = useUserAuth();

 // Your authentication logic goes here...
 
  return !user ? <Navigate to="/login" /> : <Component />;
};
export default ProtectedRoute;

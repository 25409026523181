import { addBloomLinkClickCountByBloomNameAndLinkId } from '../services/bloom.mjs'

function BloomLinks(props) {
    const { bloomName, bloomLink } = props
	const openInNewTab = (bloomName, linkId, url, e) => {
		e.preventDefault();
		addBloomLinkClickCountByBloomNameAndLinkId(bloomName, bloomLink.id);
		window.open(url, '_blank', 'noopener,noreferrer');
	};

    return (
        <li key={bloomLink.id} onClick={openInNewTab.bind(this, bloomName, bloomLink.id, bloomLink.bloomLinkURL)}>
			{bloomLink.bloomLinkImage != null &&
			<img src={bloomLink.bloomLinkImage}/>
			}
            <h6>{bloomLink.bloomLinkTitle}</h6>
        </li>
    )
}

export default BloomLinks
import { doc, setDoc, updateDoc, getDoc, increment } from "firebase/firestore";
import { getDb } from "./db.mjs"

const bloom_tap_collection_name = "bloomTaps"
const bloom_tap_view_collection_name = "bloomTapViews"

export const addBloomTapViewCountById = async id => {
	const today = new Date();
	const month = (today.getMonth() + 1).toString().padStart(2, '0')
	const year = today.getFullYear();
	const date = today. getDate().toString().padStart(2, '0');
	const currentDate = year + "-" + month + "-" + date;
	
	const isPageViewed = sessionStorage.getItem("vc" + id);
		
	if(isPageViewed == null){
		await updateDoc(doc(getDb(), bloom_tap_collection_name, id), {viewCounter: increment(1)});
		await setDoc(doc(getDb(), bloom_tap_collection_name, id, bloom_tap_view_collection_name, currentDate), {viewCounter: increment(1)}, {merge: true});
	}
	
	sessionStorage.setItem("vc" + id, true);
}

export const findBloomTapByBloomTapId = async id => {
    const querySnapshot = await getDoc(doc(getDb(), bloom_tap_collection_name, id)) 
    return querySnapshot.data()
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { deleteBookmarkById } from '../services/profile.mjs'

const handleBookmark = (bookmarkId, e) => {
    e.preventDefault();
	deleteBookmarkById(bookmarkId)
	setTimeout(function() {
	  window.location.reload(false);
	}, 1000);

};

function Bookmarks(props) {
	
    const { user, bookmark } = props
	
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	
	const openInNewTab = url => {
		window.open(url, '_self', 'noopener,noreferrer');
	  };

    return (
		<>
			<li key={bookmark.bloomName} onClick={() => openInNewTab(bookmark.bookmarkURL)}>
				<h6>{bookmark.bloomName}</h6>
			</li>
			<a href="#" onClick={handleShow}><FontAwesomeIcon icon={faTrash}  /></a>
			  <Modal show={show} onHide={handleClose} animation={false}>
				<Modal.Header closeButton>
				  <Modal.Title>Delete Bookmark</Modal.Title>
				</Modal.Header>
				<Modal.Body>{"Are you sure to delete the bookmark " + bookmark.bloomName + " ?"}</Modal.Body>
				<Modal.Footer>
				  <Button variant="secondary" onClick={handleClose}>
					Close
				  </Button>
				  <Button variant="danger" onClick={handleBookmark.bind(this, bookmark.id)}>
					Delete
				  </Button>
				</Modal.Footer>
			  </Modal>
		</>
    )
}

export default Bookmarks

import React from 'react'
import ListGroup from 'react-bootstrap/ListGroup';

function FbDataDeletion() {
    return (
        <div>
            <h4 className="pb-3">Facebook Data Deletion Instruction</h4>
			<ListGroup>
			  <ListGroup.Item>
				  <p>
				  According to the Facebook Platform rules, we have to provide User Data Deletion Callback URL or Data Deletion Instructions URL. If you want to delete your activities from the BloomTap, please follow these instructions:
				  <ul>
					<li>1. Go to Your Facebook Account's Setting & Privacy. Click " Setting ".</li>
					<li>2. Go to " Apps and Websites" and you will see all of your Apps activities.</li>
					<li>3. Select the BloomTap in the drop down box</li>
					<li>4. Click " Remove" button.</li>
				  </ul>
				  If you wish to delete user account data, you have to request us to delete your account. Subject to your BloomTap user account is no longer required in the future, please send your request with account registered email to admin@bloomtap.io. Your account will be deleted and all data will no longer be saved.
				  </p>
			  </ListGroup.Item>
			</ListGroup>
        </div>
    )
}

export default FbDataDeletion

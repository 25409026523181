import React from 'react'
import ListGroup from 'react-bootstrap/ListGroup';

function FbDataDeletion() {
    return (
        <div>
            <h4 className="pb-3">Google Data Deletion Instruction</h4>
			<ListGroup>
			  <ListGroup.Item>
				  <p>
				  If you wish to delete user account data, you have to request us to delete your account. Subject to your BloomTap user account is no longer required in the future, please send your request with account registered email to admin@bloomtap.io. Your account will be deleted and all data will no longer be saved.
				  </p>
			  </ListGroup.Item>
			</ListGroup>
        </div>
    )
}

export default FbDataDeletion

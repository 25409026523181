import './App.css';
import Layout from './components/layout.js'
import Main from './components/main.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import { UserAuthContextProvider } from "./context/UserAuthContext";
import { Helmet } from 'react-helmet';
import icBloompoint from './images/ic_bloompoint.jpg';
import './utils/smart_banner/smartbanner.css';
import './utils/smart_banner/smartbanner.js?4';

import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { addBloomViewCountByBloomName, findBloomByBloomName } from './services/bloom.mjs'
import { findAllLinkedProfilesByUid } from './services/profile.mjs'

function App() {
	const {paramBloomName} = useParams();
	
    const [bloomName, setBloomName] = useState('')
    const [bloomBio, setBloomBio] = useState('')
    const [profiles, setProfiles] = useState([])
	
    const fetchData = async () => {
		const bloomRes = await findBloomByBloomName(paramBloomName)

		const profilesRes = await findAllLinkedProfilesByUid(bloomRes.uid, bloomRes.profileIdList)
		
		await addBloomViewCountByBloomName(paramBloomName)

		setBloomName(paramBloomName)
		setBloomBio(bloomRes.bio)
		setProfiles([...profilesRes])
    }

    useEffect(() => {
		fetchData()
    })
	
  return (
	<div>
		<Helmet>
			<meta name="viewport" content="width=device-width, initial-scale=1"/>
			<meta name="smartbanner:title" content="BloomPoint"/>
			<meta name="smartbanner:author" content="Earn BloomPoint"/>
			<meta name="smartbanner:price" content="FREE"/>
			<meta name="smartbanner:price-suffix-apple" content=" - On the App Store"/>
			<meta name="smartbanner:price-suffix-google" content=" - In Google Play"/>
  			<meta name="smartbanner:icon-apple" content={icBloompoint}/>
  			<meta name="smartbanner:icon-google" content={icBloompoint}/>
  			<meta name="smartbanner:button" content="Open"/>
  			<meta name="smartbanner:button-url-apple" content="https://apps.apple.com/us/app/apple-store/id375380948"/>
  			<meta name="smartbanner:button-url-google" content="https://play.google.com/store/apps/details?id=com.duckduckgo.mobile.android&hl=en_US"/>
  			<meta name="smartbanner:enabled-platforms" content="android,ios"/>
  			<meta name="smartbanner:close-label" content="Close this banner"/>
			<title>{"BloomTap - " + bloomName}</title>
			<meta name="description" content={bloomBio} />
			<meta name="keywords" content="react, meta tags, seo" />
			<meta name="author" content="BloomTap" />
			<meta property="og:title" content={bloomName} />
			<meta property="og:description" content={bloomBio} />
			<meta property="og:image" content={profiles.length > 0  ? profiles[0].photoUrl : ''} />
			<meta property="og:url" content={"https://bloomtap.io/" + bloomName} />
			<meta name="twitter:title" content={bloomName} />
			<meta name="twitter:description" content={bloomBio} />
			<meta name="twitter:image" content={profiles.length > 0  ? profiles[0].photoUrl : ''} />
		</Helmet>
		<UserAuthContextProvider>
			<div className="app">
				<Layout>
					<Main />
				</Layout>
			</div>
		</UserAuthContextProvider>
	</div>
  );
}

export default App;

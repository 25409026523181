import { useState, useEffect } from 'react'
import { useUserAuth } from "../context/UserAuthContext";
import icFacebook from '../images/ic_facebook.png'

function FacebookSignIn (props) {
	const { handleFacebookFirebaseSignIn } = useUserAuth();

	return (
        <div className="btn-login">
            <button onClick={handleFacebookFirebaseSignIn()}>
				<img className="login-icon" src={icFacebook} alt="Facebook Icon"/><br/>
				Login with Facebook
			</button>
        </div>
    )
}
export default FacebookSignIn;

import { useState, useEffect } from 'react'
import { useUserAuth } from "../context/UserAuthContext";
import icGoogle from '../images/ic_google.png'

function GoogleSignIn (props) {
	//const { action, bloomName } = props
	const { handleGoogleFirebaseSignIn } = useUserAuth();
	
	return (
        <div className="btn-login">
            <button onClick={handleGoogleFirebaseSignIn()}>
				<img className="login-icon" src={icGoogle} alt="Google Icon"/><br/>
				Login with Google
			</button>
        </div>
    )
}
export default GoogleSignIn;

import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';

import { addBloomTapViewCountById, findBloomTapByBloomTapId } from '../services/bloom-tap.mjs'

function BloomTap() {
  const {btid} = useParams();
  const navigate = useNavigate();

  const [ipAddressView, setIPAddressView] = useState([])
  const [isActivated, setIsActivated] = useState(false)

  const fetchIPAddress = () => {
	  
	    fetch('https://geolocation-db.com/json/')
	      .then(response => response.json())
	      .then(data => {
	        setIPAddressView({
			ipAddress: data.IPv4,
			country: data.country_name,
			latitude: data.latitude,
			longitude: data.longitude
		})
	      })
	      .catch(error => console.log(error))
	 
  }	
  
  const fetchData = async () => {
    const bloomTapRes = await findBloomTapByBloomTapId(btid)
	await addBloomTapViewCountById(btid)
	
	
	if(bloomTapRes != null){
		setIsActivated(true)
		navigate("/" + bloomTapRes.bloomName + "?dpid=" + bloomTapRes.defaultProfileId)
	}
  }
  
  useEffect(() => {
    fetchData()
  }, [])
  
  return (
        <div>
            { !isActivated && 
                <center>The BloomTap doesn't exist or is not activated.</center>
            }
		</div>
	)
}

export default BloomTap

import {useSearchParams} from "react-router-dom";
import GoogleSignIn from '../components/google-login.js';
import FacebookSignIn from '../components/facebook-login.js';


function Login() {
    const [searchParams, setSearchParams] = useSearchParams();
	const action = searchParams.get("action")
	const bloomName = searchParams.get("bloomname")

    return (
        <div className="login-container">
			<GoogleSignIn/>
			<div className="gap-10"></div>
			<FacebookSignIn/>
        </div>
    )
}

export default Login
